import * as React from "react"
import Layout from "../components/layout/"
import { Container, Row, Col} from "react-bootstrap"
import { Helmet } from 'react-helmet';

// markup
const ImpressumPage = () => {

  return (
    <Layout>
      <Helmet>
        <title>Impressum - Unternehmensgruppe Urbas</title>
      </Helmet>
      <Container>
        <Row>
          <Col md={12} className="overflow-hidden" style={{height: '25vh'}}>
            <div className="bg-dark-primary parallelogram-header1-sub">
              <div className="unskew-header1-sub">
                <div id="rect-sub">
                  <div className="p-3">
                    <h1>
                      Impressum
                      <br />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="pt-3 pb-6 p-0 ">
        <div className="bg-ultralight m-1">
          <div className="container-fluid py-4 text-left" style={{maxWidth: 1000}}>
            <Row>
              <Col>
                <h4>Harald Urbas Daten & Kommunikation</h4>
                <div className="p-2">
                  <p>
                    Falkenstr. 14
                    <br></br>
                    85662 Hohenbrunn
                    <br></br>
                    Deutschland
                  </p>
                  <br></br>
                  <p>
                    fon: +49 89-13 99 93 55
                    <br></br>
                    mail: <a href="mailto:service@urbas.de">service@urbas.de</a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Rechtliche Angaben</b>
                  <br></br>
                  <p>
                    Vertretungsberechtigt: Harald Urbas

                    Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE128194153
                    Inhaltlich Verantwortlicher gemäß TMG: Harald Urbas
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Haftungshinweis</b>
                  <br></br>
                  <p>
                    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
                    Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten
                    Seiten sind ausschließlich deren Betreiber verantwortlich.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Unsere Datenschutzhinweis</b>
                  <br></br>
                  <p>
                    Harald Urbas Daten & Kommunikation freut sich über Ihren Besuch dieser Webseite.
                    Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges
                    Anliegen. Nachfolgend informieren wir Sie, zu welchen Zwecken
                    Harald Urbas Daten & Kommunikation Ihre Daten verwendet.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Grundsatz der anonymen Verarbeitung</b>
                  <br></br>
                  <p>
                    Soweit in den folgenden Abschnitten nicht anderweitig dargestellt,
                    werden bei Nutzung unserer Webseiten grundsätzlich keine personenbezogenen
                    Daten erhoben, verarbeitet oder genutzt. Bei Wahrung Ihrer Anonymität werden
                    bei Aufruf unserer Webseite Informationen übertragen, wie z.B.
                  </p>
                  <br></br>
                  <p>Browsertyp/ -version</p>
                  <p>verwendetes Betriebssystem</p>
                  <p>Referrer URL (die zuvor besuchte Seite)</p>
                  <p>Uhrzeit der Serveranfrage</p>
                  <br></br>
                  <p>
                    Diese Daten sind für Harald Urbas Daten & Kommunikation keinen
                    bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten
                    mit anderen Datenquellen wird nicht vorgenommen, die Daten werden
                    zudem nach einer statistischen Auswertung gelöscht und ausschließlich
                    dazu verwendet, die Attraktivität, Inhalte und Funktionalitäten unserer Webseiten zu verbessern.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Erhebung, Verarbeitung und Weitergabe personenbezogener Daten</b>
                  <br></br>
                  <p>
                    Darüber hinaus werden von uns personenbezogene Daten nur erhoben,
                    um die Inanspruchnahme von Telemedien zu ermöglichen und abzurechnen
                    oder wenn Sie uns diese von sich aus mitteilen, um beispielsweise mit uns in
                    Kontakt zu treten. Eine nachfolgende Verarbeitung erfolgt nur zu den Zwecken,
                    zu denen die Daten erhoben wurden. Falls weitere Verarbeitungszwecke beabsichtigt
                    sein sollten, werden Sie am Ort Ihrer Dateneingabe ausdrücklich darauf hingewiesen und,
                    falls erforderlich, um Ihre Einwilligung gebeten.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Bewerbungsverfahren</b>
                  <br></br>
                  <p>
                    Sofern Sie sich auf elektronischem Wege bei uns bewerben,
                    werden Ihre Angaben ausschließlich zur Bearbeitung Ihrer Bewerbung
                    verwendet und nicht an Dritte außerhalb von Harald Urbas Daten & Kommunikation weitergegeben.
                    Bitte beachten Sie, dass Bewerbungen, die Sie uns per E- Mail senden, unverschlüsselt übertragen
                    werden bzw. nur transportverschlüsselt sind.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Widerspruchs- und Widerrufsrecht</b>
                  <br></br>
                  <p>
                    Es besteht selbstverständlich jederzeit die Möglichkeit, dass
                    Sie der Erhebung, Verarbeitung und Nutzung Ihrer Daten widersprechen
                    und/oder Ihre Einwilligung zur Erhebung, Verarbeitung und Weitergabe
                    Ihrer personenbezogenen Daten uns gegenüber für die Zukunft widerrufen.
                    Dafür wenden Sie sich bitte an: <a href="mailto:service@urbas.de">service@urbas.de</a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Datenübermittlung an Dritte</b>
                  <br></br>
                  <p>
                    Eine Übermittlung Ihrer Daten an Dritte findet grundsätzlich
                    nicht statt, es sei denn, wir sind gesetzlich dazu verpflichtet
                    oder die Datenweitergabe ist zur Durchführung des Vertragsverhältnisses erforderlich.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Sicherheit Ihrer Daten</b>
                  <br></br>
                  <p>
                    Harald Urbas Daten & Kommunikation setzt technische und organisatorische
                    Sicherheitsmaßnahmen ein, um die von Ihnen zur Verfügung gestellten Daten
                    vor zufälligen oder vorsätzlichen Manipulationen, Verlust, Zerstörung oder
                    den Zugriff unberechtigter Personen bestmöglich zu schützen.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Auskunftsrechte und Möglichkeiten für weitere Fragen</b>
                  <br></br>
                  <p>
                    Auf schriftliche Anfrage teilen wir Ihnen gerne mit, ob und welche
                    personenbezogenen Daten von Ihnen bei uns gespeichert sind.
                    Unter den Voraussetzungen der Vorschriften des Bundesdatenschutzgesetzes
                    können Sie gegebenenfalls eine Berichtigung, Sperrung und Löschung dieser Daten verlangen.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Marken- und Urheberrechte</b>
                  <br></br>
                  <p>
                    Alle hier verwendeten Namen, Begriffe, Zeichen und Grafiken
                    können Marken- oder Warenzeichen im Besitze ihrer rechtlichen Eigentümer sein.
                    Die Rechte aller erwähnten und benutzten Marken- und Warenzeichen
                    liegen ausschließlich bei deren Besitzern.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-2">
                  <b>Bildmaterial</b>
                  <br></br>
                  <p>
                    Sofern die Bildrechte sich nicht im Eigentum von Harald Urbas
                    Daten & Kommunikation oder des jeweiligen Herstellers befinden,
                    wurden ausschließlich erworbene Bilder des lizenzvergebenden Portals: fotolia.com verwendet.
                    Lizenzgeber sind die jeweiligen Fotografen.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ImpressumPage
